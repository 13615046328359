import React from "react";
import '../css/contact.css'
import Layout from "../components/layout"

export default class Contact extends React.Component{
  componentWillMount() {
    // window.scrollTo(0,0)
  }

  render() {
    return(
      <Layout>
        <div className="contact-way">
         <img alt="aboutus" style={{width:"100%"}} src="../../images/aboutus.png"/>

        </div>
      </Layout>
    )
  }
}